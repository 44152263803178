import "../styles/globals.css";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { appWithTranslation } from "next-i18next";

import { ApiProvider, useAuth } from "../src/hooks/api";
import { RKVAuthProvider } from "../src/hooks/rkvLogin";
import theme from "../src/theme";

const customTheme = extendTheme(theme);
const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASEURL;
function MyApp({ Component, pageProps, router, err }) {
  return (
    <ApiProvider
      config={{
        apiBaseUrl,
      }}
    >
      <RKVAuthProvider>
        <ChakraProvider theme={customTheme}>
          <AnimatePresence>
            <motion.div
              key={router.route}
              initial="pageInitial"
              animate="pageAnimate"
              exit="pageExit"
              variants={{
                pageInitial: {
                  opacity: 0,
                },
                pageAnimate: {
                  opacity: 1,
                },
                pageExit: {
                  opacity: 0,
                },
              }}
              transition={{ duration: 0.25 }}
            >
              <Component {...pageProps} err={err} />
            </motion.div>
          </AnimatePresence>
        </ChakraProvider>
      </RKVAuthProvider>
    </ApiProvider>
  );
}

export default appWithTranslation(MyApp);
