import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: (properties) => ({
      body: {
        fontFamily: "body",
        lineHeight: "base",
      },
      a: {
        color: "highlight",
        textDecoration: "underline",
        fontWeight: "bold",
        _hover: {
          cursor: "pointer",
        },
      },
      h1: {
        color: "#005763",
        textTransform: "uppercase",
      },
      h2: {
        color: "#147E87",
        textTransform: "uppercase",
      },
      header: {
        bg: "baseLight",
      },
      main: {
        bg: "baseLight",
        color: "baseDark",
      },
      button: {
        fontFamily: "button",
        bg: "#232F3A",
        color: "white",
      },
      footer: {
        bg: "baseDark",
      },
    }),
  },
  colors: {
    highlight: "#78C3C9",
    baseDark: "#2f373e",
    baseNeutral: "#adc19e",
    baseLight: "#F3F4F6",
    buttonDark: "#232F3A",
    shadow: "#78C3C9",
  },
  fonts: {
    body: "Open-sans, sans-serif",
    heading: "Oswald, sans-serif",
    button: "Oswald, sans-serif",
    mono: "Menlo, monospace",
  },
});

export default theme;
